import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet es caracteritza per presentar capells viscosos i peus secs. Presenta un típic capell convex amb els contorns bruscament recorbats cap avall, de 2 a 8 cm de diàmetre, de color variable entre marró, marró grisós o grisós amb el contorn recorbat més obscur. Té nombroses làmines i un poc atapeïdes de color marró més o manco brut olivaci. El peu és irregular, de cilíndric a bulbós i curt, de 2,5 a 10 x 1 a 2,5 cm de color blanc grisaci, a vegades amb reflexes lilosos a la part superior. Les espores són de color rovell en massa, subglobuloses i berrugoses, de 6,5-9 x 5-7,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      